/*
  This is the dumb result of having to compile or include BP CSS, which breaks the whole app, to get an icon.
  The $end string section will NOT accept a hex value, do not try.
*/
@mixin getIcon($color) {
  $colorString: '#{red($color)},#{green($color)},#{blue($color)}';
  $start: "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E";
  $style: "%3Cpath fill-rule='evenodd' clip-rule='evenodd'";
  $content: "d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z'";
  $end: "fill='rgb(#{$colorString})' /%3E%3C/svg%3E";

  background-image: url('data:image/svg+xml;charset=utf-8,#{$start} #{$style} #{$content} #{$end}');
}

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before {
  .bp3-dark & {
    @include getIcon($pt-dark-text-color);
  }

  // $pt-text-color doesn't work well with primary intent background
  .bp3-body & {
    @include getIcon($etro-tertiary);
  }
}

// Make checkbox container the same size as the input
.etro-checkbox-wrapper {
  height: 16px;
  width: 16px;

  .bp3-control.bp3-checkbox {
    margin-top: unset;
    margin-bottom: unset;
  }
}
