.etro-discord-button {
  height: 30px; // have to force height because of icon
  background-image: none !important; // lightTheme adds a gradiant
  align-items: center;

  &-fill {
    @extend .etro-discord-button;
    color: $white !important;
    background-color: $discord-blurple3 !important;
  }

  &:hover {
    color: $white !important;
    background-color: $discord-blurple1 !important;
  }

  svg {
    fill: currentColor !important;
  }
}
