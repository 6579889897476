.etro-dialog-body {
  flex: 1 1 auto;
  margin: 20px;
  line-height: 18px;
}

.etro-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px;
}

.etro-dialog-footer-actions {
  display: flex;
  justify-content: flex-end;

  .bp3-button {
    margin-left: 8px;
  }
}

.etro-dialog-callout {
  margin-bottom: 20px;
}