@mixin setIconSize($width: 28px, $height: 28px) {
  width: $width;
  height: $height;
}

.bp3-body .etro-icon {
  @include setIconSize;

  fill: $pt-icon-color;
}

.bp3-dark .etro-icon {
  @include setIconSize;

  fill: $pt-dark-icon-color;
}

.bp3-body .etro-icon-small {
  @include setIconSize(24px, 24px);

  fill: $pt-icon-color;
}

.bp3-dark .etro-icon-small {
  @include setIconSize(24px, 24px);

  fill: $pt-dark-icon-color;
}

.bp3-body .etro-icon-xs {
  @include setIconSize(18px, 18px);

  fill: $pt-icon-color;
}

.bp3-dark .etro-icon-xs {
  @include setIconSize(18px, 18px);

  fill: $pt-dark-icon-color;
}