@mixin borderColor($color) {
  border-top-color: $color;
  border-bottom-color: $color;
  border-left-color: $color;
}

.etro-radio-group {
  display: flex;
  align-items: center;

  .etro-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    // min-width for buff tiers so I,II,III are same size and level sync doesn't overflow
    min-width: 49px;
    height: 30px;
    border: 1px solid;
    // Stops double borders with checked element
    border-right-color: transparent;
    // override BP
    margin: 0 !important;
    padding: 10px !important;

    .bp3-body & {
      @include borderColor($pt-text-color);

      &:last-child {
        border-right-color: $pt-text-color;
      }
    }

    .bp3-dark & {
      @include borderColor($pt-dark-text-color);

      &:last-child {
        border-right-color: $pt-dark-text-color;
      }
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .etro-radio-checked {
    color: $pt-intent-primary !important;
    border-color: $pt-intent-primary !important;
    box-shadow: 0 0 0 2px fade-out($pt-intent-primary, 0.5);

    // Hide left border on the next radio button to avoid double
    +.etro-radio {
      border-left-color: transparent;
    }
  }

  label:hover {
    color: $pt-intent-primary;
  }
}