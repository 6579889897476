@mixin setDisabledFontTheme($dark-theme: true, $font-size: 14px) {
  font-size: $font-size;

  @if $dark-theme {
    color: $pt-dark-text-color-disabled;
  } @else {
    color: $pt-text-color-disabled;
  }
}

@mixin firefoxScrollbar() {
  scrollbar-width: thin;
  scrollbar-color: $etro-scrollbar-color $etro-scrollbar-background;
}

@mixin convertOutlineToBoxShadow() {
  outline: 0;
  outline-offset: 0;
  box-shadow: 0 0 0 2px fade-out($pt-intent-primary, 0.3) !important;
  // outline: fade-out($pt-intent-primary, 0.3) solid 1px !important;
  // outline-offset: 0 !important;
}
