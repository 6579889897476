@import './mixins';

@mixin itemLevel($dark-theme: true) {
  margin-right: 6px;

  @include setDisabledFontTheme($dark-theme, $font-size: 11px);
}

@mixin stats($dark-theme: true) {
  display: flex;
  justify-content: flex-start;
  padding-left: 8px;

  @include setDisabledFontTheme($dark-theme, $font-size: 11px);

  span:not(:last-child) {
    padding: 0 10px 0 0;
  }

  &.etro-small-param {
    font-size: 9px;
  }
}

.etro-select-option {
  display: flex;
  align-items: center;
}

.etro-select-option-icon {
  margin-top: 3px;
}

.etro-select-option-text {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-materia {
    @extend .etro-select-option-text;
    flex-direction: row;
    align-items: center;
  }
}

.etro-select-option-label {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;

  &-materia {
    @extend .etro-select-option-label;
    flex: 1
  }
}

.bp3-dark {
  .etro-select-option-ilevel {
    @include itemLevel($dark-theme: true);
  }
}

.bp3-body {
  .etro-select-option-ilevel {
    @include itemLevel($dark-theme: false);
  }
}

.bp3-dark {
  .etro-select-option-stats {
    @include stats($dark-theme: true);

    &-meld-rate {
      @extend .etro-select-option-stats;
      margin-right: 6px;
      justify-content: flex-end;
    }
  }
}

.bp3-body {
  .etro-select-option-stats {
    @include stats($dark-theme: false);

    &-meld-rate {
      @extend .etro-select-option-stats;
      margin-right: 6px;
      justify-content: flex-end;
    }
  }
}

$etro-select-small-height: 30px;

.etro-select-small {
  max-height: $etro-select-small-height;

  &__control {
    max-height: $etro-select-small-height;
  }

  &__value-container {
    max-height: $etro-select-small-height;
    // Need to override the JSS style
    min-height: unset !important;
  }

  &__indicators {
    max-height: $etro-select-small-height;
  }
}