@mixin etro-form-card-wrapper {
  min-width: 320px;
}

.etro-form-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.etro-form-card-wrapper {
  @include etro-form-card-wrapper;
  width: 20%;
}

.etro-form-card-wrapper-dialog {
  @include etro-form-card-wrapper;
  margin-top: 20px;
  width: 80%;
}

.etro-form-card {
  display: flex;
  flex-direction: column;
  text-align: left;

  h2 {
    margin-top: 0;
    text-align: center;
  }
}

.etro-form-submit {
  margin-bottom: 8px;
  width: 100%;
}

.bp3-popover-target {
  width: 100%;
}

.bp3-form-group {
  margin-bottom: 10px !important;
  width: 100%;
}

.etro-form-dialog-body {
  margin: 20px 20px 0 20px;
}

.etro-form-dialog-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .bp3-button {
    margin-left: 8px;
  }
}

.bp3-input-group {
  .bp3-input-left-container {
    height: 100%;

    .etro-input-left-element {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }
}

.etro-form-dialog-condense {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.etro-form-params {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .param-margin {
    margin-left: 8px;
  }

  .etro-form-param {
    width: calc(50% - 8px);
  }
}

.etro-form-max-param-sum {
  display: flex;
  flex-direction: column;
}