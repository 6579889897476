/*
  Reserved by BP, all have 1-5 except black and white:
  white, black, dark-gray, gray, light-gray, blue, green,
  orange, red, vermilion, rose, violet, indigo, cobalt,
  turquoise, forest, lime, gold, sepia
  1 is darkest for a group
*/

$etro-pink1: #752f75;
$etro-pink2: #8f398f;
$etro-pink3: #b460a6;
$etro-pink4: #e793d9;
$etro-pink5: #ffdfff;

$etro-purple1: #603c76;

$etro-gray1: #dcdcdc;
$etro-gray2: #f5f5f5;

$etro-dark-gray1: #444444;

$etro-black1: #141414;
$etro-black2: #262626; // 1 + 7% lighten
$etro-black3: #333333; // 2 + 5% lighten
$etro-black4: #404040; // 2 + 10% lighten
$etro-black5: #4c4c4c; // 2 + 15% lighten

$etro-white1: #ebebeb;
$etro-white2: #f0f0f0;
$etro-white3: #fafafa;
$etro-white4: #ffffff;
$etro-white5: #ffffff;

$etro-green1: #0d8a56;
$etro-green2: #0f9960;
$etro-green3: #27a370;

$etro-orange1: #c37527;
$etro-orange2: #d9822b;
$etro-orange3: #dd8f40;
// Mantine colors (same index)
$etro-orange4: #ffa94d;
$etro-orange5: #ff922b;

$etro-red1: #c53131;
$etro-red2: #db3737;
$etro-red3: #df4b4b;

$discord-blurple1: #5c6dae;
$discord-blurple2: #677bc4;
$discord-blurple3: #7289da;

// Mantine colors
$etro-blue0: #e7f5ff;
$etro-blue1: #d0ebff;
$etro-blue2: #a5d8ff;
$etro-blue3: #74c0fc;
$etro-blue4: #4dabf7;
$etro-blue5: #339af0;
$etro-blue6: #228be6;
$etro-blue7: #1c7ed6;
$etro-blue8: #1971c2;
$etro-blue9: #1864ab;

// Mantine colors
$etro-yellow0: #fff9db;
$etro-yellow1: #fff3bf;
$etro-yellow2: #ffec99;
$etro-yellow3: #ffe066;
$etro-yellow4: #ffd43b;
$etro-yellow5: #fcc419;
$etro-yellow6: #fab005;
$etro-yellow7: #f59f00;
$etro-yellow8: #f08c00;
$etro-yellow9: #e67700;

// Mantine colors
$etro-violet0: #f3f0ff;
$etro-violet1: #e5dbff;
$etro-violet2: #d0bfff;
$etro-violet3: #b197fc;
$etro-violet4: #9775fa;
$etro-violet5: #845ef7;
$etro-violet6: #7950f2;
$etro-violet7: #7048e8;
$etro-violet8: #6741d9;
$etro-violet9: #5f3dc4;

// random blacks, not ordered
// $black1: #293132;
// $black2: #2d3436;
// $black3: #262626;
// $black4: #33363b;
// $black5: #393939;
// $black6: #272727;
// $black7: #333333;