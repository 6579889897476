@import './mixins';

@mixin materia-card {
  margin-top: 8px;
  min-height: 60px;
  padding: 4px;
  display: flex;
  align-items: center;

  &:not(:hover) {
    box-shadow: none !important;
  }

  &:hover {
    .etro-materia-collapse-icon {
      svg {
        fill: $pt-intent-primary;
      }
    }
  }

  .etro-materia-wrapper {
    width: 95%;
    display: flex;
    justify-content: space-around;
    font-size: 13px;

    .etro-materia-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.etro-materia-card-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.etro-materia-cap-table {
  font-size: 13px;
}

.etro-materia-overcap {
  color: $pt-intent-danger;
}

.etro-materia-exactcap {
  color: $pt-intent-success;
}

.etro-materia-collapse-icon {
  margin-left: 4px;
  align-self: center;
}

.bp3-dark {
  .etro-materia-card {
    @include materia-card;
    background-color: $etro-dark-tertiary !important;
  }
}

.bp3-body {
  .etro-materia-card {
    @include materia-card;
    background-color: $etro-tertiary !important;
  }
}


.etro-selector-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;

  h4 {
    margin: 0;
    font-size: 14px;
  }
}