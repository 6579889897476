@import '~@blueprintjs/core/src/common/colors';
@import './colors';

$etro-primary: $etro-white1;
$etro-secondary: $etro-white2;
$etro-tertiary: $etro-white3;
$etro-quaternary: $etro-white4;
$etro-quinary: $etro-white5;

.bp3-body {
  background-color: $etro-primary;

  .bp3-dialog-header {
    background-color: $etro-secondary !important;
  }

  // Dialog body background
  .bp3-dialog {
    background-color: $etro-primary;
  }

  .etro-default-toast {
    background-color: $etro-tertiary !important;
  }

  // Switch "dot"
  .bp3-switch .bp3-control-indicator::before {
    background: $etro-secondary !important;
  }

  .bp3-slider-handle {
    background: $etro-quaternary !important;
  }

  .bp3-slider-progress {
    background: $etro-tertiary !important;
  }

  .bp3-slider-progress.bp3-intent-primary {
    background: $pt-intent-primary !important;
  }

  .bp3-drawer {
    background: $etro-primary !important;

    .bp3-drawer-header {
      background: $etro-secondary !important;
    }
  }

  iframe {
    border-color: $pt-text-color-disabled;
  }
}

// Sets the colors for outlined/minimal buttons. Probably more
$pt-intent-text-colors: (
  'primary': $pt-intent-primary,
  'success': $pt-intent-success,
  'warning': $pt-intent-warning,
  'danger': $pt-intent-danger,
);

// Button,
$button-background-color: $etro-tertiary;
$button-background-color-hover: $etro-secondary;
$button-background-color-active: $etro-secondary;
$button-background-color-disabled: rgba($button-background-color, 0.7);
$button-background-color-active-disabled: rgba($button-background-color-active, 0.7);

// Inputs
$input-background-color: $etro-tertiary;
$input-background-color-disabled: rgba($etro-tertiary, 0.7);

// Switch
$switch-checked-background-color-hover: $etro-pink2;
$switch-checked-background-color-active: $etro-pink1;

// Text
$pt-text-color: $etro-dark-gray1;
$pt-text-color-muted: rgba($pt-text-color, 0.8);
$pt-text-color-disabled: rgba($pt-text-color, 0.6);
$pt-heading-color: $pt-text-color;
$pt-link-color: $pt-text-color;

// Icons
$pt-icon-color: $pt-text-color;
$pt-icon-color-hover: $pt-text-color;
$pt-icon-color-disabled: $pt-text-color-disabled;
$pt-icon-color-selected: $pt-intent-primary;

// Navbar
$pt-divider-black: rgba($black, 0.15);
$pt-divider-white: rgba($pt-text-color, 0.15);
$navbar-background-color: $etro-secondary;

// Menu
$menu-background-color: $etro-secondary;

// Shadows
$etro-elevation-shadow: 0 0 0 1px $pt-divider-black, 0 0 0 rgba($black, 0), 0 0 0 rgba($black, 0);

// Dialog
$pt-dialog-box-shadow: $etro-elevation-shadow;

// Popover
$popover-background-color: $etro-secondary;
$pt-popover-box-shadow: $etro-elevation-shadow;

// Tooltip
$pt-tooltip-box-shadow: $etro-elevation-shadow;
$tooltip-background-color: $etro-secondary;
$tooltip-text-color: $pt-text-color;

// Card
$card-background-color: $etro-secondary;
