@import '~@blueprintjs/core/src/common/colors';
@import './colors';

$etro-dark-primary: $etro-black2;
$etro-dark-secondary: $etro-black3;
$etro-dark-tertiary: $etro-black4;
$etro-dark-quaternary: $etro-black5;
$etro-dark-quinary: $etro-black5;

// Intent defaults
$pt-intent-primary: $etro-pink3;
$pt-intent-success: $etro-green3;
$pt-intent-warning: $etro-orange3;
$pt-intent-danger: $etro-red3;

// Have to set !default in ~@blueprintjs\core\src\common\_color-aliases.scss
// https://github.com/palantir/blueprint/blob/develop/packages/core/src/common/_color-aliases.scss
$pt-outline-color: rgba($pt-intent-primary, 0.6);

.bp3-dark {
  background-color: $etro-dark-primary;

  // These don't use a scss variable in BP, important is required to override
  .bp3-dialog-header {
    background-color: $etro-dark-secondary !important;
  }

  .etro-default-toast {
    background-color: $etro-dark-tertiary !important;
  }

  // Switch "dot"
  .bp3-switch .bp3-control-indicator::before {
    background: $etro-dark-secondary !important;
  }

  .bp3-slider-handle {
    background: $etro-dark-quaternary !important;
  }

  .bp3-slider-progress {
    background: $etro-dark-tertiary !important;
  }

  .bp3-slider-progress.bp3-intent-primary {
    background: $pt-intent-primary !important;
  }

  .bp3-drawer {
    background: $etro-dark-primary !important;

    .bp3-drawer-header {
      background: $etro-dark-secondary !important;
    }
  }

  iframe {
    border-color: $pt-dark-text-color-disabled;
  }
}

// "Button" intents are used in other places also
// "intent": (default, hover, active colors)
$button-intents: (
  'primary': (
    $pt-intent-primary,
    $etro-pink2,
    $etro-pink1,
  ),
  'success': (
    $pt-intent-success,
    $etro-green2,
    $etro-green1,
  ),
  'warning': (
    $pt-intent-warning,
    $etro-orange2,
    $etro-orange1,
  ),
  'danger': (
    $pt-intent-danger,
    $etro-red2,
    $etro-red1,
  ),
);

// Sets the colors for outlined/minimal buttons. Probably more
$pt-dark-intent-text-colors: (
  'primary': $pt-intent-primary,
  'success': $pt-intent-success,
  'warning': $pt-intent-warning,
  'danger': $pt-intent-danger,
);

// Scrollbar, have to be here rather than index
$etro-scrollbar-color: rgba($pt-intent-primary, 0.8);
$etro-scrollbar-background: rgba(0, 0, 0, 0.3);

// Button,
$dark-button-background-color: $etro-dark-tertiary;
$dark-button-background-color-hover: $etro-dark-secondary;
$dark-button-background-color-active: $etro-dark-secondary;
$dark-button-background-color-disabled: rgba($dark-button-background-color, 0.7);
$dark-button-background-color-active-disabled: rgba($dark-button-background-color-active, 0.7);

// Inputs
$dark-input-background-color: $etro-dark-tertiary;
$dark-input-background-color-disabled: rgba($etro-dark-tertiary, 0.7);

// Switch
$dark-switch-checked-background-color-hover: $etro-pink2;
$dark-switch-checked-background-color-active: $etro-pink1;

// Text
$pt-dark-text-color: $etro-gray1;
$pt-dark-text-color-muted: rgba($pt-dark-text-color, 0.8);
$pt-dark-text-color-disabled: rgba($pt-dark-text-color, 0.6);
$pt-dark-heading-color: $pt-dark-text-color;
$pt-dark-link-color: $pt-dark-text-color;

// Icons
$pt-dark-icon-color: $pt-dark-text-color;
$pt-dark-icon-color-hover: $pt-dark-text-color;
$pt-dark-icon-color-disabled: $pt-dark-text-color-disabled;
$pt-dark-icon-color-selected: $pt-intent-primary;

// Navbar
$pt-dark-divider-black: rgba($black, 0.4);
$pt-dark-divider-white: rgba($pt-dark-text-color, 0.15);
$dark-navbar-background-color: $etro-dark-secondary;

// Menu
$dark-menu-background-color: $etro-dark-secondary;

// Overlay
$overlay-background-color: rgba($etro-black1, 0.7);

// Shadows
$etro-dark-elevation-shadow: 0 0 0 1px $pt-dark-divider-black, 0 0 0 rgba($black, 0), 0 0 0 rgba($black, 0);

// Dialog
$pt-dark-app-background-color: $etro-dark-primary; // Doesn't actually override body
$pt-dark-dialog-box-shadow: $etro-dark-elevation-shadow;

// Popover
$dark-popover-background-color: $etro-dark-secondary;
$pt-dark-popover-box-shadow: $etro-dark-elevation-shadow;

// Tooltip
$pt-dark-tooltip-box-shadow: $etro-dark-elevation-shadow;
$dark-tooltip-background-color: $etro-dark-secondary;
$dark-tooltip-text-color: $pt-dark-text-color;

// Card
$dark-card-background-color: $etro-dark-secondary;
