@import './mixins';

$etro-dark-border: rgba($pt-dark-text-color, 0.1);
$etro-light-border: rgba($pt-text-color, 0.1);
$etro-table-border: 1px solid;

// global overrides
.rdg {
  @include firefoxScrollbar;
  --rdg-row-selected-hover-background-color: none !important;
  --rdg-checkbox-color: #{$pt-intent-primary} !important;
  --rdg-checkbox-focus-color: #{$pt-intent-primary} !important;
  --rdg-selection-color: #{$pt-intent-primary} !important;

  .rdg-row {
    &:hover {
      .rdg-cell {
        // top and bottom only sudo outline since display:contents doesn't support outline on row
        box-shadow: inset 0px 1px 1px 0px $pt-intent-primary, inset 0px -1px 1px 0px $pt-intent-primary;
      }
    }
  }

  .etro-dg-row-hover {
    cursor: pointer;

    .rdg-cell {
      &[aria-selected='true'] {
        box-shadow: none;
      }
    }
  }

  .rdg-cell {
    &[aria-selected='true'] {
      box-shadow: inset 0 0 0 1px $pt-intent-primary;
    }
  }
}

.etro-dg-dark {
  background-color: $etro-dark-quaternary;

  .rdg-header-row {
    background-color: $etro-dark-tertiary;
  }

  .rdg-row {
    background-color: $etro-dark-quaternary;
  }

  .rdg-cell {
    border-bottom: $etro-table-border $etro-dark-border;

    &:not(:last-child) {
      border-right: $etro-table-border $etro-dark-border;
    }

    &:last-child {
      // rdg will still apply the default border
      border-right: none;
    }
  }

  .etro-dg-no-data {
    background-color: $etro-dark-quaternary;
    grid-column: 1/-1;
    @include setDisabledFontTheme($dark-theme: true, $font-size: 13px);
  }

  .rdg-checkbox {
    --background-color: #{$etro-dark-secondary} !important;
    background-color: $etro-dark-secondary;
  }
}

.etro-dg-light {
  background-color: $etro-quaternary;

  .rdg-header-row {
    background-color: $etro-tertiary;
  }

  .rdg-row {
    background-color: $etro-quaternary;
  }

  .rdg-cell {
    border-bottom: $etro-table-border $etro-light-border;

    &:not(:last-child) {
      border-right: $etro-table-border $etro-light-border;
    }

    &:last-child {
      // rdg will still apply the default border
      border-right: none;
    }
  }

  .etro-dg-no-data {
    background-color: $etro-quaternary;
    grid-column: 1/-1;
    @include setDisabledFontTheme($dark-theme: false, $font-size: 13px);
  }

  .rdg-checkbox {
    --background-color: #{$etro-secondary} !important;
    background-color: $etro-secondary;
  }
}

.etro-dg-header {
  display: flex;
  justify-content: space-between;

  &-actions {
    display: flex;
  }

  &-filters {
    display: flex;
  }
}

.etro-dg-filter-remove {
  align-self: center;
  margin-top: 12px;
}

.job-icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  span {
    padding: 0 8px;
  }

  &-start {
    @extend .job-icon-cell;
    justify-content: flex-start;
  }
}

.gearset-list-actions {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;

  .bp3-popover-target {
    display: flex;
    align-items: center;
    height: 100%;
  }
}