.etro-buffs-wrapper {
  margin-top: 9px;
  margin-bottom: 10px;
}

.etro-gearset-card-stats {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-around;
  min-height: 17px;

  &.etro-small-param {
    font-size: 11px;
  }
}

.etro-gearset-card-button {
  margin-top: 8px;
  width: 100%;
}

.etro-food-capped {
  color: $pt-intent-success;
}