@import '~katex/dist/katex.min.css';
@import '~react-data-grid/lib/styles.css';


@import './darkTheme';
@import './lightTheme';

// Components alphabetized
@import './confirmDialog';
@import './discordButton';
@import './etroButton';
@import './etroCheckbox';
@import './etroDataGrid';
@import './etroForm';
@import './etroFormGroup';
@import './etroIcon';
@import './etroRadioGroup';
@import './etroSelect';
@import './gearset';
@import './gearsetSidebar';
@import './materiaCard';
@import './newVersionDialog';
@import './passwordReset';
@import './slider';

// From https://img.finalfantasyxiv.com/lds/pc/global/fonts//FFXIV_Lodestone_SSF.woff
// https://thewakingsands.github.io/ffxiv-axis-font-icons/
// Adds FFXIV unicode characters
@font-face {
  font-family: 'FFXIV';
  src: local('FFXIV'), url(../fonts/FFXIV_Lodestone_SSF.woff) format('opentype');
}

// Icons16 is for inline BP Icons
$pt-font-family: 'Open Sans', 'Roboto', 'Oxygen', 'Helvetica Neue', 'Icons16', sans-serif, 'FFXIV';

html {
  height: 100vh;
  overflow: hidden;
}

body {
  @include firefoxScrollbar;
  text-align: center;
  height: 100vh;
}

@supports (scrollbar-gutter: stable) {
  body {
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
}

@supports (overflow-y: overlay) {
  body {
    overflow-y: overlay;
    scrollbar-gutter: auto;
  }
}

a {
  text-decoration: none !important;
}

// Chrome Scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px $etro-scrollbar-background;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 50px $etro-scrollbar-color;
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 50px $etro-scrollbar-color;
}

.etro-inline-link {
  display: inline-flex;
  align-items: flex-start;
  color: $etro-blue4 !important;

  span {
    padding-left: 4px;
  }
}

.etro-ccpa-link {
  a span {
    color: $etro-blue4;
    font-size: 12px;
  }
}

// Default Tabler icon settings
.tabler-icon {
  width: 20px;
  height: 20px;
  stroke-width: 1.75;
}

// Match Mantine overlay so modals/toast/etc are above header
.bp3-overlay {
  z-index: 201 !important;
}

.bp3-portal {
  z-index: 201 !important;
}

// This needs to be at the bottom to be able to override anything
@import '~@blueprintjs/core/src/blueprint';