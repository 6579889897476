.etro-password-reset {
  width: 250px;
  padding: 20px 20px 10px 20px;
  text-align: left;
}

#etro-close-button {
  position: absolute;
  top: 1%;
  right: 2px;
}