.etro-form-group-wrapper {
  display: flex;
  justify-content: space-between;

  &.bp3-inline {
    align-items: center !important;
  }

  .etro-form-group-label {
    display: flex;
    align-items: center;

    img {
      height: 24px;
      margin-right: 8px;
    }
  }
}
