@import './mixins';

@mixin setTabBoxShadow($color) {
  //https://css-tricks.com/three-sided-border/
  -webkit-box-shadow: inset -1px 0 0 $color, inset 0 -1px 0 $color, inset 0 1px 0 $color !important;
  -moz-box-shadow: inset -1px 0 0 $color, inset 0 -1px 0 $color, inset 0 1px 0 $color !important;
  box-shadow: inset -1px 0 0 $color, inset 0 -1px 0 $color, inset 0 1px 0 $color !important;
}

$sidebar-width: 334px;

.etro-gearset-sidebar {
  @include firefoxScrollbar;
  display: flex;
  flex-direction: column;
  min-width: $sidebar-width;
  max-height: 100vh;
  margin-right: 1%;
  padding: 20px 20px 5px 20px !important;
  text-align: left;
  overflow-y: auto;
  border-top-right-radius: 0 !important;

  .etro-gearset-buttons .bp3-form-content {
    display: flex;
    justify-content: space-between;
  }

  .etro-gearset-sidebar-slider {
    width: 90%; // stop the labels from going into margin
    margin: auto;
  }

  .etro-exact-tier {
    color: $pt-intent-success;
  }
}

.etro-param-table-wrapper {
  margin-bottom: 15px;
}

.etro-param-table {
  text-align: center;
  font-size: 13px;
  overflow: hidden;
}

.etro-param-table-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  label {
    margin-bottom: 0 !important;
  }

  button {
    margin-left: 8px;
  }

  .table-collapse-button {
    margin-left: auto;
  }
}

.materia-icon-cell {
  display: flex;
  align-items: center;
  justify-content: left;

  span {
    margin-left: 8px;
  }
}

.etro-gearset-sidebar-close {
  display: flex;
  justify-content: flex-end;
  margin: -17px -5px -10px 0;
}

.etro-gearset-sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
}

.etro-sidebar-drawer {
  width: $sidebar-width !important;
}

.etro-sidebar-tabs {
  .bp3-tab-list {
    margin-top: -1px;
  }

  .bp3-tab-indicator {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  .bp3-tab-panel {
    padding-left: 0 !important;
  }

  .etro-sidebar-tab {

    // Need body tags to override important in bp3
    .bp3-body & {
      &-inactive {
        background-color: $etro-secondary !important;
      }

      @include setTabBoxShadow($pt-divider-black);
    }

    .bp3-dark & {
      &-inactive {
        background-color: $etro-dark-secondary !important;
      }

      @include setTabBoxShadow(rgba($black, 0.6));
    }

    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

// Need to override tab here because it shares the color with links
.bp3-tab-indicator-wrapper {
  color: $pt-intent-primary !important;

  .bp3-tab-indicator {
    background-color: $pt-intent-primary !important;
  }
}