@import './mixins';

@mixin setButtonHoverColor($hover-color) {
  &:not([disabled]):hover {
    background-color: rgba($hover-color , 0.05) !important;
    border-color: $hover-color !important;

    svg,
    span svg {
      fill: $hover-color;
    }

    .bp3-button-text {
      color: $hover-color;
    }
  }
}

.etro-button-hover-primary-fill {
  @include setButtonHoverColor($pt-intent-primary);
}

.etro-button-hover-success-fill {
  @include setButtonHoverColor($pt-intent-success);
}

.etro-button-hover-warning-fill {
  @include setButtonHoverColor($pt-intent-warning);
}

.etro-button-hover-danger-fill {
  @include setButtonHoverColor($pt-intent-danger);
}

.etro-button-hover-discord-fill {
  @include setButtonHoverColor($discord-blurple1);
}

.etro-no-pad {
  padding: 0 !important;
}

.etro-full-width {
  width: 100%;
}

.etro-button-height {
  height: 30px;
}

.etro-button-margin {
  margin-left: 8px;
}

.etro-button-margin-sm {
  margin-left: 4px;
}

.bp3-button {
  &:focus {
    @include convertOutlineToBoxShadow();
  }
}

.etro-button-popover-target {
  height: 30px;
  width: 30px !important;
}

.etro-split-button-group {
  display: flex;

  .etro-split-button {
    &-first {
      flex: 2;
      border-radius: 3px 0 0 3px;
    }

    &-last {
      border-radius: 0 3px 3px 0;
    }
  }

  .bp3-popover-wrapper {
    flex: 0 1 !important;
  }
}

.etro-button-group-outlined {
  :not(:first-child) {
    .etro-split-button-first {
      border-radius: 0;
    }
  }

  .etro-split-button-group {
    flex: 3;
  }
}

/* 
  Partial work towards changing the text color for intent buttons.
  Right now all intents use pure white (#ffffff) for text and icons
  and for both themes. The normal text colors do not look great
  either though and this CSS breaks the hover fills above.
*/
// .bp3-button {
//   svg,
//   span svg {
//     fill: $pt-dark-text-color;
//   }

//   .bp3-button-text {
//     .bp3-dark & {
//       color: $pt-dark-text-color;
//     }

//     .bp3-body & {
//       color: $pt-text-color;
//     }
//   }
// }