.etro-new-version-wrapper {
  display: flex;
  flex-direction: column;

  // Add bottom margin to all children except last
  & > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
